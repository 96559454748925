import * as React from "react";
import styled from 'styled-components';
import searchIcon from '../../images/common/search_icon.png';

const FreeWordSearch = () => {
  const [ search_text, setState ] = React.useState('');

  const handleInputChange = (e) => {
    setState(e.target.value);
  }

  const handleSubmit = (event) => {
    if((search_text != "") && search_text.match(/\S/g)) {
      setState(search_text.trim());
      return true;
    } else {
      event.preventDefault();
    }
  };

  return (
    <>
      <FreeWordSearchForm action="/list_search/" method="get" onSubmit={handleSubmit}>
        <SearchArea>
        <FreeWordSearchTextArea type="text" name="freeword" value={search_text} onChange={handleInputChange} maxLength={100} placeholder="病院名など"/>
        </SearchArea>
        <SearchArea>
        <FreeWordSearchButton type="submit" id="search_freeword"><SearchIcon/></FreeWordSearchButton>
        </SearchArea>
      </FreeWordSearchForm>
    </>
  );
}

export default FreeWordSearch;


const FreeWordSearchForm = styled.form`
  margin: 15px 0 0;
  display: table;
  width: 100%;
  vertical-align: middle;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
`

const SearchArea = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`

const FreeWordSearchTextArea = styled.input`
  display: inline-block;
  vertical-align: middle;
  height: 45px;
  line-height: 45px;
  width: 100%;
  max-width: inherit;
  padding: 0 10px;
  border: #d7d7d7 1px solid;
  border-radius: 5px 0 0 5px;
  border-right: none;
`

const FreeWordSearchButton = styled.button`
  display: inline-block;
  vertical-align: middle;
  height: 45px;
  line-height: 45px;
  width: 45px;
  color: #fff;
  background: linear-gradient(#ff678f, #ff4b7a);
  border-radius: 0 5px 5px 0;
  &::before{
    background-image: url(${searchIcon});
  }
`

const SearchIcon = styled.div`
  display: block;
  vertical-align: middle;
  height: 18px;
  width: 18px;
  margin: auto;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`