import ReactOnRails from 'react-on-rails';

import FollowUpButton from '../src/components/top/FollowUpButton';
import LocationSearchModal from '../src/components/top/LocationSearchModal';
import CategoriesSearchModal from '../src/components/top/CategoriesSearchModal';
import NurseTypesSearchModal from '../src/components/top/NurseTypesSearchModal';
import FreeWordSearch from '../src/components/top/FreeWordSearch';
import Search from '../src/components/top/Search';
import NewJobs from '../src/components/top/NewJobs';
import PickupJobs from '../src/components/top/PickupJobs'


// 利用するコンポーネントの登録
ReactOnRails.register({
  FollowUpButton,
  LocationSearchModal,
  CategoriesSearchModal,
  NurseTypesSearchModal,
  FreeWordSearch,
  Search,
  NewJobs,
  PickupJobs,
});
