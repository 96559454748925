import * as React from "react";
import * as ReactDOM from "react-dom";
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Styles from "./style";
import categoriesSearchIcon from '../../images/common/categories_search_icon.png';

function CategoriesSearchModal(props) {
  const categoryItems = JSON.parse(props.params);

  const [documentLoaded, setDocumentLoaded] = React.useState(false);
  const [modalEnableState, setModalEnableState] = React.useState(false);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  // 初回documentレンダリング時にのみ発火する
  React.useEffect(() => {
    setDocumentLoaded(true);
  }, [setDocumentLoaded]);

  // モーダル開いたときに後ろのHTMLがスクロールすることを防ぐためbodyを固定する
  function setBodyFixed(){
    if (documentLoaded){
      const ua = window.navigator.userAgent.toLowerCase();
      const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

      isiOS ? setBodyOverFlowForiOS() : setBodyOverFlowForNoniOS();
    }
  }

  // iOSはposition: fixedを使うが、スクロール位置が保持されないため別途スクロール位置を保持して変更してあげる必要がある
  function setBodyOverFlowForiOS(){
    if (!modalEnableState){
      setScrollPosition(window.pageYOffset);
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
    } else {
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      window.scrollTo(0, scrollPosition);
    }
  }

  // iOS以外はoverflowをhiddenにする
  function setBodyOverFlowForNoniOS(){
    document.body.style.overflow = modalEnableState ? "auto" : "hidden";
  }

  const categoryLiItems = (items) => {
    return items.map((item) =>
      <CategoryLi key={item.id}>
        <CategoryButton href={item.url}>{item.keyword}</CategoryButton>
      </CategoryLi>
    )
  };

  const categoryGroupSearchItems= categoryItems.map((item) =>
    <CategoriesSearchLi key={item.group_type}>
      <CategoryTitle>{item.label}</CategoryTitle>
      <CategoryUl>
        {categoryLiItems(item.categories)}
      </CategoryUl>
    </CategoriesSearchLi>
  );

  const categoriesModal = (
    <Modal isEnable = {modalEnableState}>
      <ModalWindow>
        <ModalBody>
          <ModalSearchHeader>
            <Rainbowline><RainbowlineHr /></Rainbowline>
            <ModalSearchTittle>こだわり条件から探す</ModalSearchTittle>
            <ModalCloseButton onClick={() => {
                setModalEnableState(false);
                setBodyFixed();
                }}>
              <ModalCloseButtonIcon />閉じる
            </ModalCloseButton>
          </ModalSearchHeader>
          <ModalMainSection>
            <CategoriesSearchUl>
              {categoryGroupSearchItems}
            </CategoriesSearchUl>
          </ModalMainSection>
        </ModalBody>
      </ModalWindow>
    </Modal>
  );

  const categoriesButton = (
    <ButtonWithImage
      onClick={() => {
        setModalEnableState(true);
        setBodyFixed();
      }}
    >
      <ButtonLabels>
        こだわり条件から探す
      </ButtonLabels>
    </ButtonWithImage>
  )

  return (
    <>
      {categoriesModal}
      {categoriesButton}
    </>
  );
}

interface Modal {
  isEnable: boolean,
}

CategoriesSearchModal.propTypes = {
  categoryItems: PropTypes.arrayOf(PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired),
    label: PropTypes.string.isRequired,
    group_type: PropTypes.string.isRequired,
  }).isRequired)
};

export const Modal = styled.div<Modal>`
  display: ${({ isEnable }) => ( (isEnable) ? "flex" : "none" )};
`

export const ModalWindow = styled.div`
  ${Styles.ModalWindowStyle}
`
export const ModalBody = styled.div`
  ${Styles.ModalBodyStyle}
`

export const ModalSearchHeader = styled.div`
  ${Styles.ModalSearchHeaderStyle}
`
export const Rainbowline = styled.div`
  ${Styles.RainbowlineStyle}
`
export const RainbowlineHr = styled.hr`
  ${Styles.RainbowlineHrStyle}
`
export const ModalSearchTittle = styled.div`
  ${Styles.ModalSearchTitleStyle}
`
export const ModalCloseButton = styled.div`
  ${Styles.ModalCloseButtonStyle}
`
export const ModalCloseButtonIcon = styled.span`
  ${Styles.ModalCloseButtonIconStyle}
`
export const ModalMainSection = styled.section`
  ${Styles.ModalMainSectionStyle}
`

export const CategoriesSearchUl = styled.ul`
  ${Styles.ModalGroupedUlStyle}
`
export const CategoriesSearchLi = styled.li`
  ${Styles.ModalGroupedLiStyle}
  &:last-child {
    margin-bottom: 80px;
  }
`

export const CategoryTitle = styled.h2`
  ${Styles.ModalGroupedTitleStyle}
`
export const CategoryUl = styled.ul`
  ${Styles.ModalGroupedChildUlStyle}
`
export const CategoryLi = styled.li`
  ${Styles.ModalGroupedChildLiStyle}
`
export const CategoryButton = styled.a`
  ${Styles.ModalGroupedChildButtonStyle}
`

export const ButtonWithImage = styled.li`
  &::before{
    background-image: url(${categoriesSearchIcon});
  }
  ${Styles.ButtonStyle}
`
export const ButtonLabels = styled.label`
  ${Styles.ButtonLabelStyle}
  width: 23vw;
`
export default props => <CategoriesSearchModal {...props} />;
