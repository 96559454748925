import { css } from 'styled-components';
import searchIconPink from '../../images/common/search_icon_pk.png';

export const ButtonStyle = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 106px;
  margin: 0;
  font-size: 1rem;
  color: #333333;
  background: #fff;
  border-radius: 5px;
  border: #d7d7d7 solid 1px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  &:active{
    background-color: #ffe9f0;
  }
  &:before{
    content: '';
    opacity: 1;
    width: 100%;
    height: 36px;
    margin: 8px auto 0;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`
export const ButtonLabelStyle = css`
  padding: 0;
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 3.6vw;
  line-height: 1.3;
  width: 15vw;
`

// --- Modal Styles ---
export const ModalWindowStyle = css`
  z-index: 100000;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`
export const ModalBodyStyle = css`
  z-index: +1;
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: #fff0f1;
`
export const ModalSearchHeaderStyle = css`
  z-index: +1;
  position: fixed;
  left: 0;
  width: 100%;
  height: 67px;
  background: #fff;
  text-align: left;
  margin-bottom: 20px;
  line-height: 1;
  font-size: 1.7rem;
  font-weight: bold;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
`
export const ModalSearchHeadingStyle = css`
  background: #fff;
  padding: 35px 0 15px 40px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
  border-top: #d7d7d7 1px solid;
  margin: 15px -15px 5px;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    left: 15px;
    margin-top: -3px;
    background: url(${searchIconPink}) no-repeat left top;
    background-size: 19px auto;
`
export const RainbowlineStyle = css`
  display: block;
  width: 100%;
  overflow: hidden;
  height: 7px;
  max-height: 7px;
  background: url(images/z-topia/line_mirror_rainbow.png) no-repeat left top;
  background-size: 100% auto;
`
export const RainbowlineHrStyle = css`
  border: none;
`

export const ModalSearchTitleStyle = css`
  text-align: center;
  padding: 22px;
  font-size: 1.15rem;
  font-weight: bold;
`
export const ModalBackButtonStyle = css`
  z-index: +1;
  position: absolute;
  top: 33%;
  left: 0;
  bottom: 0;
  padding: 10px 15px 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
`
export const ModalBackButtonIconStyle = css`
  &::before{
    content: '←';
    height: 50%;
  }
`
export const ModalCloseButtonStyle = css`
  z-index: +1;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  padding: 10px 15px 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
`
export const ModalCloseButtonIconStyle = css`
  &::before, ::after{
    content: '';
    position: absolute;
    top: -5px;
    right: 32%;
    width: 22px;
    height: 1px;
    background-color: #dbdbdb;
  }
  &::before{
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &::after{
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
export const ModalMainSectionStyle = css`
  position: relative;
  padding: 67px 15px 0;
  margin: 0;
`

// --- Lists in Modal ---
export const ModalUlStyle = css`
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
`
export const ModalHalfWidthLiStyle = css`
  position: relative;
  display: block;
  height: 45px;
  border-bottom: #d7d7d7 1px solid;
  width: 49%;
  text-align: center;
  background: #fff;
  border: #e0ddd7 solid 1px;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
  &:nth-child(odd) {
    float: left;
    margin: 0 0 10px 0;
  }
  &:nth-child(even) {
    float: right;
    margin: 0 0 10px 0;
  }
`
export const ModalLiButtonStyle = css`
  display: block;
  position: relative;
  height: 45px;
  padding: 15px 10px;
  font-size: 1.0rem;
  line-height: 1.0rem;
  font-weight: bold;
  text-decoration: none;
  &:active{
    background-color: #ffe9f0;
  }
  &::after{
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    right: 10px;
    width: 8px;
    height: 8px;
    margin-top: -5px;
    border-top: solid 1px #ff4b7a;
    border-right: solid 1px #ff4b7a;
    transform: rotate(45deg);
  }
`
export const ModalSingleFullWidthButtonStyle = css`
  margin: 15px 0;
  position: relative;
  display: table;
  height: 45px;
  width: 100%;
  text-align: center;
  padding-top: 15px;
  font-size: 1.0rem;
  line-height: 1.0rem;
  font-weight: bold;
  background: #fff;
  border: #d7d7d7 1px solid;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
  &:active{
    background-color: #ffe9f0;
  }
  /* ボタンの矢印 */
  &::after {
    content: "";
    position: absolute;
    top: 45%;
    right: 25px;
    width: 8px;
    height: 8px;
    border-top: 1px solid #ff678f;
    border-right: 1px solid #ff678f;
    transform: rotate(45deg) translateY(-50%);
  }
`
export const ModalLumpedUlStyle = css`
  margin-top: 20px;
  width: 100%;
  background: #fff;
  border: #d7d7d7 1px solid;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
`
export const ModalLumpedLiStyle = css`
  position: relative;
  display: block;
  height: 45px;
  padding: 0px 15px;
  &:active {
    background-color: #ffe9f0;
  }
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    display: inline-block;
    width: 90%;
    height: 1px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #d7d7d7;
  }
  &:first-child {
    &::before {
      content: none;
    }
  }
  &:last-child {
    height: 50px;
  }
`
export const ModalLumpedButtonStyle = css`
  display: block;
  position: relative;
  height: 45px;
  padding: 17px 10px;
  font-size: 1.0rem;
  line-height: 1.0rem;
  font-weight: bold;
  text-decoration: none;
  &::after{
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    right: 10px;
    width: 8px;
    height: 8px;
    margin-top: -5px;
    border-top: solid 1px #ff4b7a;
    border-right: solid 1px #ff4b7a;
    transform: rotate(45deg);
  }
`
export const ModalGroupedUlStyle = css`
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  display: table;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
`
export const ModalGroupedLiStyle = css`
  position: relative;
  display: block;
  margin: 0 15px;
  height: 100%;
  margin-left: 0;
  margin-bottom: 25px;
  padding: 10px;
  width: 100%;
  background: #fff;
  border: #d7d7d7 1px solid;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
`
export const ModalGroupedTitleStyle = css`
  padding: 10px 0 10px;
  font-size: 1.15rem;
  font-weight: bold;
`
export const ModalGroupedChildUlStyle = css`
  width: 100%;
  border-radius: 5px;
  display: table;
  overflow: hidden;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
`
export const ModalGroupedChildLiStyle = css`
  position: relative;
  display: block;
  height: 45px;
  width: 49%;
  text-align: center;
  background: #fff;
  border: #e0ddd7 solid 1px;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
  &:active{
    background-color: #ffe9f0;
  }
  &:nth-child(odd) {
    float: left;
    margin: 0 0 10px 0;
  }
  &:nth-child(even) {
    float: right;
    margin: 0 0 10px 0;
  }
`
export const ModalGroupedChildButtonStyle = css`
  display: flex;
  position: relative;
  height: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 15px 17px 15px 8px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 3.2vw;
  line-height: 3.2vw;
  font-weight: normal;
  text-decoration: none;
  &::after{
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    right: 10px;
    width: 8px;
    height: 8px;
    margin-top: -5px;
    border-top: solid 1px #ff4b7a;
    border-right: solid 1px #ff4b7a;
    transform: rotate(45deg);
  }
`