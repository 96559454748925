import * as React from "react";
import styled from 'styled-components';

const Search = () => {
  return (
    <>
      <SearchButton href="/search/">
        条件を指定して探す
      </SearchButton>
    </>
  );
}

export default Search;

const SearchButton = styled.a`
  margin: 15px auto 0;
  display: table;
  position: relative;
  height: 50px;
  width: 70%;
  text-align: center;
  padding-top: 15px;
  font-size: 1.15em;
  line-height: 1.15em;
  color: #666666;
  background: linear-gradient(#fafafa, #f8f8f8);
  border: #d7d7d7 1px solid;
  border-radius: 25px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
  /* ボタンの矢印 */
  &::after {
    content: "";
    position: absolute;
    top: 48%;
    right: 6vw;
    width: 8px;
    height: 8px;
    border-top: 1px solid #ff678f;
    border-right: 1px solid #ff678f;
    transform: rotate(45deg) translateY(-50%);
  }
`