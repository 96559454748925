import * as PropTypes from 'prop-types';
import * as React from "react";
import {useState, useEffect} from "react";
import styled, {keyframes} from 'styled-components';
import { Link } from 'react-scroll';

const FollowUpButton = (props) => {
    const [navbar, setNavbar] = useState(false)
    const [pageTopButton, setPageTopButton] = useState(false)
    const [navShowedFlg, setNavShowedFlg] = useState(false)
    const [pageTopButtonShowedFlg, setPageTopButtonShowedFlg] = useState(false)

    useEffect(() => {
      const scrollNav = () => {
        if (window.scrollY >= 180) {
          setNavbar(true);
          setNavShowedFlg(true);
        }else{
          setNavbar(false);
        }
        if (window.scrollY >= 1536) {
          setPageTopButton(true);
          setPageTopButtonShowedFlg(true);
        }else{
          setPageTopButton(false);
        }
      };      scrollNav()

      window.addEventListener("scroll", scrollNav)

      return () => {
        window.removeEventListener('scroll', scrollNav);
      };
    }, [])

    return (
      <>
      <PageTopButton active = {pageTopButton} is_page_top_button_showed = {pageTopButtonShowedFlg} >
        <Link to="wrapper" smooth={true} duration={600}><TopLink></TopLink></Link>
      </PageTopButton>
      <Nav active = {navbar} is_nav_showed = {navShowedFlg} dangerouslySetInnerHTML={{ __html: props.html }} />
      </>
    );
};

FollowUpButton.propTypes = {
  html: PropTypes.elementType.isRequired,
};

export default props => <FollowUpButton {...props} />;

interface Nav {
  active: boolean,
  is_nav_showed: boolean,
}
interface PageTopButton {
  active: boolean,
  is_page_top_button_showed: boolean,
}

const HorizontalSlideIn = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
`
const HorizontalSlideOut = keyframes`
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
`

const VerticalSlideIn = keyframes`
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
`
const VerticalSlideOut = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
`

const Nav = styled.nav<Nav>`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 85px;
  left: 0;
  bottom: 0;
  padding: 0 15px;
  z-index: 10;
  text-align: center;
  display: ${({ is_nav_showed }) => ( (is_nav_showed) ? "flex" : "none" )};
  // TODO: 三項演算子on三項演算子になってるのでなんとかしたい
  animation: ${
    ({ is_nav_showed, active }) => ( (is_nav_showed) ? (active) ? VerticalSlideIn : VerticalSlideOut : "none" )
  }  0.4s ease-out forwards;
`

const PageTopButton = styled.p<PageTopButton>`
  z-index: 20;
  position: fixed;
  width: 85px;
  height: 50px;
  right: -100px;
  bottom: 100px;
  text-align: center;
  float: right;
  list-style-type: none;
  transform: translateX(0);
  display: ${({ is_page_top_button_showed }) => ( (is_page_top_button_showed) ? "flex" : "none" )};
  animation: ${
    ({ is_page_top_button_showed, active }) => ( (is_page_top_button_showed) ? (active) ? HorizontalSlideIn : HorizontalSlideOut : "none" )
  }  0.4s ease-out forwards;
`

const TopLink = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-indent: 100%;
  &::before, ::after{
    position: absolute;
    content: '';
    display: inline-block;
  }
  &::after {
    width: 50px;
    height: 50px;
    top: 0px;
    right: 15px;
    background: linear-gradient(#ff678f, #ff4b7a);
    border-radius: 100%;
    box-shadow: 0 3px 5px rgb(0 0 0 / 25%);
  }
  &::before {
    z-index: 22;
    top: 20px;
    width: 14px;
    height: 14px;
    right: 33px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-135deg);
  }
`