import * as React from "react";
import * as ReactDOM from "react-dom";
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Styles from "./style";
import nurseTypesSearchIcon from '../../images/common/nurse_types_search_icon.png';

function NurseTypesSearchModal(props) {
  const nurseTypes = JSON.parse(props.params);

  const [documentLoaded, setDocumentLoaded] = React.useState(false);
  const [modalEnableState, setModalEnableState] = React.useState(false);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  // 初回documentレンダリング時にのみ発火する
  React.useEffect(() => {
    setDocumentLoaded(true);
  }, [setDocumentLoaded]);

  // モーダル開いたときに後ろのHTMLがスクロールすることを防ぐためbodyを固定する
  function setBodyFixed(){
    if (documentLoaded){
      const ua = window.navigator.userAgent.toLowerCase();
      const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

      isiOS ? setBodyOverFlowForiOS() : setBodyOverFlowForNoniOS();
    }
  }

  // iOSはposition: fixedを使うが、スクロール位置が保持されないため別途スクロール位置を保持して変更してあげる必要がある
  function setBodyOverFlowForiOS(){
    if (!modalEnableState){
      setScrollPosition(window.pageYOffset);
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
    } else {
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      window.scrollTo(0, scrollPosition);
    }
  }

  // iOS以外はoverflowをhiddenにする
  function setBodyOverFlowForNoniOS(){
    document.body.style.overflow = modalEnableState ? "auto" : "hidden";
  }

  const nurseTypesSearchItems = nurseTypes.map((item) =>
  <NurseTypeLi key={item.id}><NurseTypeButton href={item.url}>{item.label}</NurseTypeButton></NurseTypeLi>
  );

  const nurseTypesModal = (
    <Modal isEnable = {modalEnableState}>
      <ModalWindow>
        <ModalBody>
          <ModalSearchHeader>
            <Rainbowline><RainbowlineHr /></Rainbowline>
            <ModalSearchTitle>職種から探す</ModalSearchTitle>
            <ModalCloseButton onClick={() => {
                setModalEnableState(false);
                setBodyFixed();
                }}>
              <ModalCloseButtonIcon />閉じる
            </ModalCloseButton>
          </ModalSearchHeader>
          <ModalMainSection>
            <NurseTypesUl>
              {nurseTypesSearchItems}
            </NurseTypesUl>
          </ModalMainSection>
        </ModalBody>
      </ModalWindow>
    </Modal>
  );

  const nurseTypesButton = (
    <ButtonWithImage
      onClick={() => {
        setModalEnableState(true);
        setBodyFixed();
      }}
    >
      <ButtonLabels>
        職種から探す
      </ButtonLabels>
    </ButtonWithImage>
  )

  return (
    <>
      {nurseTypesButton}
      {nurseTypesModal}
    </>
  );
}

interface Modal {
  isEnable: boolean,
}

NurseTypesSearchModal.propTypes = {
  nurseTypeItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired)
};


export const Modal = styled.div<Modal>`
  display: ${({ isEnable }) => ( (isEnable) ? "flex" : "none" )};
`

export const ModalWindow = styled.div`
  ${Styles.ModalWindowStyle}
`
export const ModalBody = styled.div`
  ${Styles.ModalBodyStyle}
`
export const ModalSearchHeader = styled.div`
  ${Styles.ModalSearchHeaderStyle}
`
export const Rainbowline = styled.div`
  ${Styles.RainbowlineStyle}
`
export const RainbowlineHr = styled.hr`
  ${Styles.RainbowlineHrStyle}
`
export const ModalSearchTitle = styled.div`
  ${Styles.ModalSearchTitleStyle}
`
export const ModalCloseButton = styled.div`
  ${Styles.ModalCloseButtonStyle}
`
export const ModalCloseButtonIcon = styled.span`
  ${Styles.ModalCloseButtonIconStyle}
`
export const ModalMainSection = styled.section`
  ${Styles.ModalMainSectionStyle}
`

export const NurseTypesUl = styled.ul`
  ${Styles.ModalUlStyle}
`
export const NurseTypeLi = styled.li`
  ${Styles.ModalHalfWidthLiStyle}
`
export const NurseTypeButton = styled.a`
  ${Styles.ModalLiButtonStyle}
`

export const ButtonWithImage = styled.li`
  &::before{
    background-image: url(${nurseTypesSearchIcon});
  }
  ${Styles.ButtonStyle}
`
export const ButtonLabels = styled.label`
  ${Styles.ButtonLabelStyle}
`
export default props => <NurseTypesSearchModal {...props} />;
