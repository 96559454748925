// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "./destyle.css"
import "../src/scss/common"
import "../src/scss/module"
import "./react-components-bundle"

// imageファイルを呼び出すための設定
const images = require.context("../src/images/", true)
const imagePath = name => images(name, true)

Rails.start()
