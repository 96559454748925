import * as React from "react";
import * as ReactDOM from "react-dom";
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Styles from "./style";

import searchIconPink from '../../images/common/search_icon_pk.png';
import searchMap from '../../images/common/location-search_map.png';
import areasSearchIcon from '../../images/common/areas_search_icon.png';

function NewLocationSearchModal(props) {
  const params = JSON.parse(props.params);
  const areaItems = params.searches.prefectures;
  const popularAreaItems = params.searches.popularAreas;

  const [documentLoaded, setDocumentLoaded] = React.useState(false);
  const [locationModalEnableState, setLocationModalEnableState] = React.useState(false);
  const [prefectureModalEnableStates, setPrefectureModalEnableStates] = React.useState({});

  const [scrollPosition, setScrollPosition] = React.useState(0);

  // 初回documentレンダリング時にのみ発火する
  React.useEffect(() => {
    setDocumentLoaded(true);
  }, [setDocumentLoaded]);

  // モーダル開いたときに後ろのHTMLがスクロールすることを防ぐためbodyを固定する
  function setBodyFixed(){
    if (documentLoaded){
      const ua = window.navigator.userAgent.toLowerCase();
      const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

      isiOS ? setBodyOverFlowForiOS() : setBodyOverFlowForNoniOS();
    }
  }

  // iOSはposition: fixedを使うが、スクロール位置が保持されないため別途スクロール位置を保持して変更してあげる必要がある
  function setBodyOverFlowForiOS(){
    if (!locationModalEnableState){
      setScrollPosition(window.pageYOffset);
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
    } else {
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      window.scrollTo(0, scrollPosition);
    }
  }

  // iOS以外はoverflowをhiddenにする
  function setBodyOverFlowForNoniOS(){
    document.body.style.overflow = locationModalEnableState ? "auto" : "hidden";
  }

  const popularAreaSearchItems = popularAreaItems.map((item) =>
    <PrefectureLi key={item.id}><PrefectureButton href={item.url}>{item.label}</PrefectureButton></PrefectureLi>
  );

  const prefectureLiItems = (items) => {
    return items.map((item) =>
      <PrefecturesLi key={item.pref_id}>
        <PrefecturesButton href={item.url}>{item.pref_label}</PrefecturesButton>
      </PrefecturesLi>
    )
  };

  const prefecturesSearchItems = areaItems.map((item) =>
    <PrefecturesSearchLi key={item.area_id}>
      <PrefecturesSearchLabel onClick={() => setPrefectureModalEnableStates((state) => ({...state, [item.area_id]: true}))}>
          {item.area_label}
      </PrefecturesSearchLabel>
      <Modal isEnable = {prefectureModalEnableStates[item.area_id]}>
        <ModalWindow>
          <PrefModalBody>
            <ModalSearchHeader>
              <Rainbowline><RainbowlineHr /></Rainbowline>
              <ModalBackButton onClick={() => setPrefectureModalEnableStates((state) => ({...state, [item.area_id]: false}))}>
              <ModalBackButtonIcon />戻る
              </ModalBackButton>
              <ModalSearchTitle>都道府県を選択</ModalSearchTitle>
              <ModalCloseButton onClick={() => {
                setPrefectureModalEnableStates((state) => ({...state, [item.area_id]: false}))
                setLocationModalEnableState(false);
                setBodyFixed();
                }}>
              <ModalCloseButtonIcon />閉じる
              </ModalCloseButton>
            </ModalSearchHeader>
            <ModalMainSection>
              <PrefecturesUl>
                {prefectureLiItems(item.prefectures)}
              </PrefecturesUl>
            </ModalMainSection>
          </PrefModalBody>
        </ModalWindow>
      </Modal>
    </PrefecturesSearchLi>
  );


  const locationModal = (
    <Modal isEnable = {locationModalEnableState}>
      <ModalWindow>
        <ModalBody>
          <ModalSearchHeader>
            <Rainbowline><RainbowlineHr /></Rainbowline>
            <ModalSearchTitle>地域から探す</ModalSearchTitle>
            <ModalCloseButton onClick={() => {
                setLocationModalEnableState(false);
                setBodyFixed();
                }}>
              <ModalCloseButtonIcon />閉じる
            </ModalCloseButton>
          </ModalSearchHeader>
          <ModalMainSection>

            <PrefectureSearchArea>
              <PrefectureSearchHeading>
                都道府県から探す
              </PrefectureSearchHeading>
              <PrefecturesSearchUl>
                {prefecturesSearchItems}
              </PrefecturesSearchUl>
            </PrefectureSearchArea>

            <ModalSearchHeading>沿線から探す</ModalSearchHeading>
            <RailSearchButton href="/search/line/">
              路線・駅から探す
            </RailSearchButton>

            <ModalSearchHeading>人気のエリアから探す</ModalSearchHeading>
            <PrefectureUl>
              {popularAreaSearchItems}
            </PrefectureUl>
          </ModalMainSection>
        </ModalBody>
      </ModalWindow>
    </Modal>
  );

  const locationButton = (
    <ButtonWithImage
      onClick={() => {
        setLocationModalEnableState(true);
        setBodyFixed();
      }}
    >
      <ButtonLabels>
        地域から探す
      </ButtonLabels>
    </ButtonWithImage>
  )

  return (
    <>
      {locationButton}
      {locationModal}
    </>
  );
}

interface Modal {
  isEnable: boolean,
}

export const Modal = styled.div<Modal>`
  display: ${({ isEnable }) => ( (isEnable) ? "flex" : "none" )};
`

export const ModalWindow = styled.div`
  ${Styles.ModalWindowStyle}
`

export const ModalBody = styled.div`
  ${Styles.ModalBodyStyle}
`
export const PrefModalBody = styled.div`
  ${Styles.ModalBodyStyle}
  z-index: 100003;
`
export const ModalSearchHeader = styled.div`
  ${Styles.ModalSearchHeaderStyle}
`
export const Rainbowline = styled.div`
  ${Styles.RainbowlineStyle}
`
export const RainbowlineHr = styled.hr`
  ${Styles.RainbowlineHrStyle}
`
export const ModalSearchTitle = styled.div`
  ${Styles.ModalSearchTitleStyle}
`
export const ModalSearchHeading = styled.div`
  ${Styles.ModalSearchHeadingStyle}
`
export const ModalBackButton = styled.div`
  ${Styles.ModalBackButtonStyle}
`
export const ModalBackButtonIcon = styled.span`
  ${Styles.ModalBackButtonIconStyle}
`
export const ModalCloseButton = styled.div`
  ${Styles.ModalCloseButtonStyle}
`
export const ModalCloseButtonIcon = styled.span`
  ${Styles.ModalCloseButtonIconStyle}
`
export const ModalMainSection = styled.section`
  ${Styles.ModalMainSectionStyle}
`

export const PrefectureSearchArea = styled.div`
  background: #edfafc;
  margin: -15px;
  padding: 10px 15px 40px;
  &::before {
    z-index: 0;
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 200px;
    top: 172px;
    right: 10%;
    background: url(${searchMap}) no-repeat right top;
    background-size: contain;
`
export const PrefectureSearchHeading = styled.div`
  padding: 10px 0 0 40px;
  margin: 20px -15px 15px;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    left: 15px;
    margin-top: -3px;
    background: url(${searchIconPink}) no-repeat left top;
    background-size: 19px auto;
`
export const PrefecturesSearchUl = styled.ul`
  width: 100%;
  height: 300px;
  display: block;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
`
export const PrefecturesSearchLi = styled.li`
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  height: 58px;
  width: 29.5%;
  background: #fff;
  border: #d7d7d7 1px solid;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
  &:active{
    background-color: #ffe9f0;
  }
  &::after{
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    right: 10px;
    width: 8px;
    height: 8px;
    margin-top: -5px;
    border-top: solid 1px #ff4b7a;
    border-right: solid 1px #ff4b7a;
    transform: rotate(45deg);
  }
  &:nth-child(1) {
    top: 135px;
    right: 35.25%;
  }
  &:nth-child(2) {
    top: 384px;
    right: 4%;
  }
  &:nth-child(3) {
    top: 200px;
    right: 35.25%;
  }
  &:nth-child(4) {
    top: 384px;
    right: 35.25%;
  }
  &:nth-child(5) {
    top: 200px;
    right: 66.5%;
  }
  &:nth-child(6) {
    top: 135px;
    right: 66.5%;
  }
  &:nth-child(7) {
    top: 384px;
    right: 66.5%;
  }
`
export const PrefecturesSearchLabel = styled.span`
  display: flex;
	align-items: center;
	justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 1.0rem;
  font-weight: bold;
  text-decoration: none;
  white-space: pre-line;
`
export const PrefecturesUl = styled.ul`
  ${Styles.ModalLumpedUlStyle}
`
export const PrefecturesLi = styled.li`
${Styles.ModalLumpedLiStyle}
`
export const PrefecturesButton = styled.a`
${Styles.ModalLumpedButtonStyle}
`

export const PrefectureUl = styled.ul`
  ${Styles.ModalUlStyle}
  &:last-child {
    margin-bottom: 60px;
  }
`
export const PrefectureLi = styled.li`
  ${Styles.ModalHalfWidthLiStyle}
`
export const PrefectureButton = styled.a`
  ${Styles.ModalLiButtonStyle}
`

const RailSearchButton = styled.a`
  ${Styles.ModalSingleFullWidthButtonStyle}
`

export const ButtonWithImage = styled.li`
  &::before{
    background-image: url(${areasSearchIcon});
  }
  ${Styles.ButtonStyle}
`
export const ButtonLabels = styled.label`
  ${Styles.ButtonLabelStyle}
`
export default props => <NewLocationSearchModal {...props} />;
