import * as React from "react";
import styled from 'styled-components';
import pickupIcon from '../../images/common/ico_pickup.png';

const PickupJobs = (props) => {

  const pickupJobs = JSON.parse(props.params);

  const pickupJobsItems = pickupJobs.map((item) =>
    <PickupJobsItemsLi key={item.office_id}>
      <a href={item.job_url}>
        <PickupJobsItemsOfficeImage src={item.office_img_url} alt={item.office_name} />
        <PickupJobsItemsTextArea>
          <PickupJobsItemsOfficeName>{item.office_name}</PickupJobsItemsOfficeName>
          <PickupJobsItemsOfficeCatchPhrase>{item.catch_phrase}</PickupJobsItemsOfficeCatchPhrase>
        </PickupJobsItemsTextArea>
      </a>
    </PickupJobsItemsLi>
  );
  return (
    <>
    <PickupJobsWrap>
      <PickupJobsHeadingArea>
        <PickupJobsHeading>PICK UP 看護師求人</PickupJobsHeading>
      </PickupJobsHeadingArea>
      <SlideScrollWrap>
      <PickupJobsItemsUl>
        {pickupJobsItems}
      </PickupJobsItemsUl>
      </SlideScrollWrap>
    </PickupJobsWrap>
    </>
  );
};

export default PickupJobs;

const PickupJobsWrap = styled.div`
  background: #fff0f1;
  margin: 30px -15px;
  border-bottom: #d7d7d7 solid 1px;
`;
const PickupJobsHeadingArea = styled.div`
background: #fff;
border-bottom: #d7d7d7 solid 1px;
`;
const PickupJobsHeading = styled.h2`
  position: relative;
  margin: 25px 15px 15px;
  padding-left: 35px;
  text-indent: -2.5rem;
  font-size: 5.5vmin;
  font-weight: bold;
  line-height: 7.15vw;
  &::before {
    content: "";
    display: inline-block;
    width: 28px;
    height: 19px;
    margin: 0 10px 5px 0;
    vertical-align: text-top;
    background: url(${pickupIcon}) no-repeat;
    background-size: 28px 19px;
  }
`;
const SlideScrollWrap = styled.div`
  overflow-x: auto;
  padding: 0 15px;
`;
const PickupJobsItemsUl = styled.ul`
  white-space: nowrap;
  display: table;
  margin: 0 -15px 20px 0;
`;
const PickupJobsItemsLi = styled.li`
  display: inline-table;
  width: 100%;
  max-width: 160px;
  margin: 20px 15px 0 0;
  background: #fff;
  border: #d7d7d7 solid 1px;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
`;
const PickupJobsItemsTextArea = styled.div`
  height: 110px;
  white-space: normal;
  padding: 15px 10px;
  line-height: 1.5;
`;
const PickupJobsItemsOfficeName = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2; /*max2行まで*/
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #ff4b7a;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
`;
const PickupJobsItemsOfficeImage = styled.img`
  width: 100%;
  height: 110px;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
  object-position: 100% 0;
`;
const PickupJobsItemsOfficeCatchPhrase = styled.p`
  font-size: 0.85rem;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*max2行まで*/
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
