import * as React from "react";
import styled from 'styled-components';
import newArrivalIcon from '../../images/common/ico_newarrival.png';
import payIcon from '../../images/common/icon_pay.png';
import placeIcon from '../../images/common/icon_place.png';

const NewJobs = (props) => {

  const newJobs = JSON.parse(props.params);

  const newJobsItems = newJobs.map((item) =>
    <NewJobsItemsLi key={item.office_id}>
      <a href={item.job_url}>
      <NewJobsItemImg src={item.office_img_url} alt={item.office_name} />
      <NewJobsItemTextArea>
        <a href={item.office_url}>
          <NewJobsItemOfficeName>{item.office_name}</NewJobsItemOfficeName>
        </a>
        <NewJobsItemJobTitle>{item.job_title}</NewJobsItemJobTitle>
        <NewJobsItemTextUl>
          {item.job_pay_model && (<NewJobsItemPayModel>{item.job_pay_model}</NewJobsItemPayModel>)}
          <NewJobsItemOfficePlace>{item.office_place}</NewJobsItemOfficePlace>
        </NewJobsItemTextUl>
      </NewJobsItemTextArea>
      </a>
    </NewJobsItemsLi>
  );
  return (
    <>
      <NewJobsWrap>
        <NewJobsHeadingArea>
          <NewJobsHeading>新着の看護師求人</NewJobsHeading>
        </NewJobsHeadingArea>
        <SlideScrollWrap>
          <NewJobsItemsUl>
            {newJobsItems}
          </NewJobsItemsUl>
        </SlideScrollWrap>
        <MoreNewJobsLinkArea>
          <MoreNewJobsLinkText>
            <a href="/list_new/">一覧を見る</a>
          </MoreNewJobsLinkText>
        </MoreNewJobsLinkArea>
      </NewJobsWrap>
    </>
  );
};

export default NewJobs;

const NewJobsWrap = styled.div`
  background: #fff0f1;
  margin: 30px -15px;
`;
const SlideScrollWrap = styled.div`
  overflow-x: auto;
  padding: 0 15px;
`;
const NewJobsHeadingArea = styled.div`
  background: #fff;
  border-bottom: #d7d7d7 solid 1px;
`;
const NewJobsHeading = styled.h2`
  position: relative;
  margin: 25px 15px 15px;
  padding-left: 35px;
  text-indent: -2.5rem;
  font-size: 5.5vmin;
  font-weight: bold;
  line-height: 7.15vw;
  &::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 22px;
    margin: 0 10px 2px 0;
    vertical-align: middle;
    background: url(${newArrivalIcon}) no-repeat;
    background-size: 18px 22px;
  }
`;
const NewJobsItemsUl = styled.ul`
  white-space: nowrap;
  display: table;
  margin: 0 -15px 15px 0;
`;
const NewJobsItemsLi = styled.li`
  display: inline-table;
  width: 240px;
  margin: 20px 15px 0 0;
  background: #fff;
  border: #d7d7d7 solid 1px;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
`;
const NewJobsItemImg = styled.img`
  width: 100%;
  height: 150px;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
  object-position: 100% 0;
`;
const NewJobsItemTextArea = styled.div`
  height: 160px;
  white-space: normal;
  padding: 10px 10px;
  line-height: 1.5;
`;
const NewJobsItemTextUl = styled.ul`
  margin-top: 0.5rem;
`;
const NewJobsItemPayModel = styled.li`
  display: -webkit-box;
  width: 100%;
  margin-right: 15px;
  -webkit-line-clamp: 1; /*max1行まで*/
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.85rem;
  line-height: 1.8;
  &::before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin: 0 5px 4px 0;
    background: url(${payIcon}) no-repeat;
    background-size: 13px 13px;
  }
`;
const NewJobsItemOfficePlace = styled.li`
  display: -webkit-box;
  width: 100%;
  margin-right: 15px;
  -webkit-line-clamp: 1; /*max1行まで*/
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.85rem;
  line-height: 1.8;
  &::before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin: 0 5px 4px 0;
    background: url(${placeIcon}) no-repeat;
    background-size: 13px 13px;
  }
`;
const NewJobsItemOfficeName = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2; /*max2行まで*/
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #ff4b7a;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
const NewJobsItemJobTitle = styled.h4`
  display: -webkit-box;
  -webkit-line-clamp: 2; /*max2行まで*/
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const MoreNewJobsLinkArea = styled.div`
  background: #fff0f1;
  border-bottom: #d7d7d7 solid 1px;
`;
const MoreNewJobsLinkText = styled.p`
  text-align: right;
  padding: 5px 35px 15px 0;
  font-size: 3.7vw;
  &::after{
    content: "";
    display: block;
    position: absolute;
    right: 25px;
    width: 8px;
    height: 8px;
    margin-top: -1rem;
    border-top: solid 1px #ff4b7a;
    border-right: solid 1px #ff4b7a;
    transform: rotate( 45deg );
  }
`;
